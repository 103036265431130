import ApiService from '../api-service';

class AuthApiService extends ApiService {
  signIn = async (data) => await this.post('/token', data).then((res) => res.data);

  logout = async () => await this.get('/logout').then((res) => res.data);

  getAuthUser = async () => await this.get('/profile').then((res) => res.data);
}

export const AuthApi = new AuthApiService('user');
